import axios from "axios";

const baseUrl = "https://admin.mail.piratskastranka.si/";

export const api = {

  //user actions

  async profile(reqOptions: any): Promise<any> {
    try {
      return (await axios.get(baseUrl + "profile", reqOptions)).data;
    } catch (err) {
      console.log(err);
    }
  },

  async updateProfilePassword(reqOptions: any, password: string): Promise<any> {
    try {
      await axios.post(baseUrl + "profile", { password: password }, reqOptions);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  },


  //admin actions

  async email(reqOptions: any): Promise<any> {
    try {
      return (await axios.get(baseUrl + "email", reqOptions)).data;
    } catch (err) {
      console.log(err);
    }
  },

  async updateUserPassword(
    reqOptions: any,
    email: string,
    password: string
  ): Promise<any> {
    try {
      await axios.post(
        baseUrl + "email/" + email,
        { password: password },
        reqOptions
      );
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  },

  async createEmailAccount(
    reqOptions: any,
    email: string,
    password: string
  ): Promise<any> {
    try {
      await axios.put(
        baseUrl + "email/" + email,
        { password: password },
        reqOptions
      );
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  },

  async createEmailAlias(
    reqOptions: any, 
    email: string,
    alias: string
  ): Promise<any> {
    try {
      await axios.put(
        baseUrl + "alias/" + email + "/" + alias, 
        reqOptions
      );
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
};
