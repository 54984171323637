import { createStore } from "vuex";
import Keycloak, { KeycloakConfig, KeycloakInstance } from "keycloak-js";
import { api } from "@/api";
import Vue from "vue";
import { useRoute } from "vue-router";

const config: KeycloakConfig = {
  url: "https://auth.piratskastranka.si/auth/", //process.env.VUE_APP_KEYCLOAK_URL,
  realm: "Pirati", //process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: "dashboard", //process.env.VUE_APP_KEYCLOAK_CLIENT_ID
};

const keycloak = Keycloak(config);

export default createStore({
  state: {
    keycloak: keycloak,
    profile: {
      aliases: [],
      email: "",
      quota: "",
      quotaUsage: "0",
    },
    emailProfiles: [],
  },
  getters: {
    reqOptions(state) {
      return {
        headers: {
          Authorization: "Bearer " + state.keycloak.token,
        },
      };
    },

    user(state) {
      console.log(state.keycloak);
      return "user";
    },

    isAdmin(state) {
      console.log(state.keycloak);
      //todo: we need exact info on the structure here
      //keycloak.resourceAccess ima sledeča objekta account, mail-admin
      //ki imata potem objekt roles, ki vsebuje array pravic takole:
      //account: manage-account, manage-account-links, view-profile
      //mail-admin: admin
      //tukaj verjetno rabim mail-admin: admin
      //return state.keycloak.resourceAccess.['mail-admin'].roles.contains('admin')
      return state.keycloak.hasResourceRole("admin", "mail-admin");
    },
  },
  mutations: {},
  actions: {

    //keycloak actions

    async keycloakInit(): Promise<boolean> {
      return await keycloak.init({
        onLoad: "login-required",
      });
    },

    async keycloakUpdateToken({ state }): Promise<void> {
      if (state.keycloak.authenticated) {
        const res = await keycloak.updateToken(60);
      }
    },


    //user actions

    async getProfile({ state, getters }): Promise<void> {
      state.profile = await api.profile(getters.reqOptions);
    },

    async updateProfilePassword({ state, getters }, payload): Promise<boolean> {
      return await api.updateProfilePassword(getters.reqOptions, payload);
    },


    //admin actions
    
    async getEmail({ state, getters }): Promise<void> {
      state.emailProfiles = await api.email(getters.reqOptions);
    },

    async updateUserPassword({ state, getters }, payload): Promise<boolean> {
      return await api.updateUserPassword(getters.reqOptions, payload.email, payload.password);
    },

    async createEmailAccount({ state, getters }, payload): Promise<boolean> {
      return await api.createEmailAccount(getters.reqOptions, payload.email, payload.password);
    },

    async createAlias({ state, getters }, payload): Promise<boolean> {
      return await api.createEmailAlias(getters.reqOptions, payload.email, payload.alias);
    },

  },
  modules: {},
});
