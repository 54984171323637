
import { defineComponent } from 'vue';
import store from "../store/index";

export default defineComponent({
  name: "Header",
  data(){
      return {

      }
  },
  computed: {
    user() : string {
        return store.getters.user
    },
    isAdmin() : boolean {
        return store.getters.isAdmin
    }
  }
});
