
import { defineComponent } from "vue";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import PageContent from "@/components/PageContent.vue";

export default defineComponent({
  name: "Home",
  components: {
    HelloWorld, PageContent
  },
});
