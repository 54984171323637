
import { defineComponent } from "vue";

export default defineComponent({
  name: "HelloWorld",
  props: {
    msg: String,
  },
  methods: {
    test(): string {
      return "test"
    }
  }
});
