import { createApp } from "vue";
import App from "./App.vue";
import keycloak from "./plugins/keycloak";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

/*const app = createApp(App)
    .use(store)
    .use(router)
    .use(keycloak)


app.provide('test', 'Dober dan')

app.config.globalProperties.$keycloak
    .init({
        onLoad: "login-required"
    })
    .then((authenticated: boolean) => {
        console.log(authenticated)
        if(authenticated){
            app.provide('keycloak', app.config.globalProperties.$keycloak)
            app.mount("#app")
            console.log(app)
        }
    }).catch(() => {
        alert("failed to init")
    })*/

//mislim, da je tale approach ok, edino izgleda da če dam keycloak objekt v api.js
//potem ni persistent čez requeste, torej moram ta objekt hraniti drugje, torej v store

const app = createApp(App)
    .use(store)
    .use(router)

/*api.keycloakInit()
.then((auth: boolean) => {
    console.log(auth)
    app.mount("#app")
}).catch(() => {
    alert("failed to init")
})*/

store.dispatch('keycloakInit')
.then((auth: boolean) => {
    app.mount("#app")
}).catch(() => {
    alert("failed to init")
})

