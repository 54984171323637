<template>
    <div class="page-content">
        <slot></slot>
    </div>
</template>

<style lang="scss">
    .page-content {
        max-width: 1140px;
        margin-left: auto;
        margin-right: auto;
    }
</style>